<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('double_major_applications')+' - '+$t('academic_unit_office')"
                        @filter-div-status="datatable.filterStatus = $event"
                        :isColumns="true"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('double_major_applications')+' - '+$t('student_affairs')"
                              @filter-div-status="datatable.filterStatus = $event"
                              :isColumns="true"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
                :export-excel="checkPermission('doublemajor_excelexport')"
                @exportExcel="downloadExcel"
            >
                <b-row>
                    <b-col md="6" lg="2">
                        <b-form-group :label="$t('id')">
                            <b-form-input v-model="datatable.queryParams.filter.id"/>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="2">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input v-model="datatable.queryParams.filter.student_number"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="2">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name"/>
                        </b-form-group>
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.surname"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"/>
                        </b-form-group>
                        <b-form-group :label="$t('program')">
                            <program-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                               v-model="datatable.queryParams.filter.program_code"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="3" lg="2">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox code="minor_application_statuses"
                                                 v-model="datatable.queryParams.filter.status"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                :lineNumbers="false"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="detailFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" v-if="checkPermission('doublemajorpreference_approveforacademicunitoffice')">
                <template v-slot:CommonModalTitle>
                    {{ formProcess=='listOfFiles' ? $t('files').toUpper() : $t('detail').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <detail-form :DoubleMajorApplication="formData"
                                 @updated="getRows"
                                 v-if="formProcess=='detail'"
                    />
                    <list-of-files :documents="formData.documents"
                                   :is_download="true"
                                   :double_major_id="formData.id"
                                   v-if="formProcess=='listOfFiles'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Component
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import CommonModal from '@/components/elements/CommonModal';
import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import ListOfFiles from "@/modules/doubleMajor/application/ListOfFiles";

// Service
import DoubleMajorPreferenceService from "@/services/DoubleMajorPreferenceService";
import DoubleMajorService from "@/services/DoubleMajorService";

// Pages

import DetailForm from './DetailForm'
// Other
import qs from 'qs';

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        FacultySelectbox,
        ProgramSelectbox,
        ParameterSelectbox,
        ListOfFiles,

        DetailForm
    },
    metaInfo() {
        return {
            title: this.$t('double_major_applications') + ' - ' + this.$t('student_affairs')
        };
    },
    data() {
        return {
            formId: 0,
            formData: {},
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('detail'),
                                class: 'ri-edit-box-line',
                                callback: (row) => {
                                    this.showDetail(row.id, row);
                                },
                                permission:"doublemajorpreference_approveforacademicunitoffice"
                            },
                            {
                                text: this.$t('document_show'),
                                class: 'ri-eye-line',
                                callback: (row) => {
                                    this.showListOfFiles(row);
                                },
                                permission: "doublemajorpreference_approveforacademicunitoffice"
                            }
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        sortable: false,
                        hidden: true,
                    },
                    {
                        label: this.$t('period') + ' / ' + this.$t('semester'),
                        field: 'semester',
                        sortable: false,
                        hidden: true,
                        formatFn: (value, row) => {
                            return row.academic_year + ' / ' + this.getSemester(value);
                        }
                    },
                    {
                        label: this.$t('student_number'),
                        field: 'student_number',
                        sortable: true,
                        hidden: false,
                    },
                    {
                        label: this.$t('full_name'),
                        field: 'name',
                        sortable: true,
                        hidden: false,
                        formatFn: (value, row) => {
                            return row.name+' '+row.surname
                        }
                    },
                    {
                        label: this.$t('preferences').toUpper(),
                        field: 'double_major_preferences',
                        sortable: false,
                        hidden: false,
                        formatFn: (value) => {
                            let html = '';
                            if (value.length > 0) {
                                html += '<ol class="pl-2 m-0">'
                                value.forEach((item) => {
                                    html += '<li>' + item.faculty_name + '<br>' + item.program_name + '</li>'
                                })
                                html += '</ol>'
                            }

                            return html;
                        }
                    },
                    {
                        label: this.$t('result').toUpper(),
                        field: 'approved_faculty',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            if (row.approved_faculty && row.approved_program) {
                                return row.approved_faculty + '<br>' + row.approved_program;
                            } else {
                                return '-'
                            }
                        }
                    },
                    {
                        label: this.$t('status').toUpper(),
                        field: 'status',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            let variant = 'badge-light';
                            if (value.includes('waiting')) variant = 'badge-warning';
                            else if (value == 'declined') variant = 'badge-danger';
                            else if (value == 'approved') variant = 'badge-success';

                            return '<span class="badge ' + variant + '">' + row.status_text + '</span>';
                        }
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        };
    },
    methods: {
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return DoubleMajorPreferenceService.academicUnitOffice(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        showDetail(id, row) {
            this.formId = id
            this.formData = row
            this.formProcess = 'detail'
            this.$refs.detailFormModal.$refs.commonModal.show()
        },
        showDetailSuccess() {
            this.$refs.detailFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        showListOfFiles(row) {
            this.formData = row
            this.formProcess = 'listOfFiles'
            this.$refs.detailFormModal.$refs.commonModal.show()
        },
        formClear() {
            this.formId = null
            this.formData = {}
            this.formProcess = null
        },
        downloadExcel(){
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            DoubleMajorService.downloadAsExcel(config)
                .then(res=>this._downloadFile(res,this.$t('double_major_applications')+'.xlsx'))
                .catch(err=>this.showErrors(err))
        }
    }
};
</script>

